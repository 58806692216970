import { Component, OnDestroy, AfterViewInit, OnInit, Renderer2 } from '@angular/core';
import { delay, withLatestFrom, takeWhile } from 'rxjs/operators';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { StateService } from '../../../@core/data/state.service';
import { EventEmitterService } from '../../../services/event-emitter.service';
import { LayoutService } from './../../../@core/data/layout.service';
import { CorpColorService } from '../../../@core/data/corp-color.service';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  template: `
    <nb-layout [center]="layout.id === 'center-column'">
      <nb-layout-header [ngStyle]="{'border-bottom': '5px solid ' + corp_color}" [ngClass]="{'border-unset': corp_color == '#ffffff'}" fixed>
        <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"></ngx-header>
      </nb-layout-header>

      

      <nb-sidebar class="menu-sidebar"
                   tag="menu-sidebar"
                   responsive
                   [end]="sidebar.id === 'end'">
        <nb-sidebar-header *ngIf="currentTheme !== 'corporate'">
          <a href="#" class="btn btn-hero-success main-btn">
            <i class="ion ion-social-github"></i> <span>Support Us</span>
          </a>
        </nb-sidebar-header>
        <ngx-moka-menu></ngx-moka-menu>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-column start class="small" *ngIf="layout.id === 'two-column' || layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-column class="small" *ngIf="layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>



      <nb-sidebar class="settings-sidebar"
                   tag="settings-sidebar"
                   state="collapsed"
                   fixed
                   [end]="sidebar.id !== 'end'">
        <ngx-theme-settings></ngx-theme-settings>
      </nb-sidebar>
    </nb-layout> 
  
    <!-- <nb-layout-footer fixed>
        <ngx-footer class="footer"></ngx-footer>
    </nb-layout-footer> -->
  `,
})
export class SampleLayoutComponent implements OnDestroy, OnInit {

  isfullscreen: Boolean = false;
  isSideBarExpanded: Boolean = true;
  corp_color: string = '#ffffff';

  subMenu: NbMenuItem[] = [
    {
      title: 'PAGE LEVEL MENU',
      group: true,
    },
    {
      title: 'Buttons',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/buttons',
    },
    {
      title: 'Grid',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/grid',
    },
    {
      title: 'Icons',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/icons',
    },
    {
      title: 'Modals',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/modals',
    },
    {
      title: 'Typography',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/typography',
    },
    {
      title: 'Animated Searches',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/search-fields',
    },
    {
      title: 'Tabs',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/tabs',
    },
  ];
  layout: any = {};
  sidebar: any = {};

  private alive = true;

  currentTheme: string;

  constructor(protected stateService: StateService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService,
    private renderer: Renderer2,
    private layoutService: LayoutService,
    private corpLogoService: CorpColorService) {

    this.sidebarService.onToggle().subscribe(data => { this.isSideBarExpanded = !this.isSideBarExpanded });
    this.sidebarService.onExpand().subscribe(data => { this.isSideBarExpanded = true });
    this.sidebarService.onCollapse().subscribe(data => { this.isSideBarExpanded = false });
    EventEmitterService.get('bottomScrolled').subscribe(data => {

      let currentHeight: any = <HTMLElement>document.querySelector('.scrollable');

      currentHeight.style.setProperty('transition', 'max-height .4s', 'important');

      if (data === true) {
        this.isSideBarExpanded ?
          currentHeight.style.setProperty('max-height', 'calc(100vh - 4.75rem - 120px)', 'important') :
          currentHeight.style.setProperty('max-height', 'calc(100vh - 4.75rem - 160px)', 'important');
      } else {
        currentHeight.style.setProperty('max-height', 'calc(100vh - 4.75rem - 45px)', 'important');
      }
    });

    this.stateService.onLayoutState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((layout: string) => this.layout = layout);

    this.stateService.onSidebarState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName('is');
    this.menuService.onItemSelect()
      .pipe(
        takeWhile(() => this.alive),
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
      )
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {

        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });

    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
      });
  }
  async ngOnInit() {
    await this.getCorpColor();
  }

  async getCorpColor() {
    let serverResponse;
    try {
      await this.corpLogoService.getCorpColor().subscribe((data) => {
        if (data.status) {
          console.log(data.error);
          return;
        }
        if (data.user.theme) {
          this.corp_color = data.user.theme.tertiary.value;
        }
      });
    }

    catch (err) {
      console.log(err);
    }

  }

  ngAfterViewInit() {

    EventEmitterService.get('reportPage').subscribe(data => {

      let btn = document.querySelectorAll('.btn-resize');

      if (data === true) {
        if (btn.length === 0) {
          let mainContainer = document.querySelector('.main-container');

          const divRow = this.renderer.createElement('div');
          const fullScreenButton = this.renderer.createElement('div');
          const expandButton = this.renderer.createElement('div');
          this.renderer.setAttribute(mainContainer, 'style', 'background: #fff;');

          this.renderer.addClass(divRow, 'row');
          this.renderer.setAttribute(divRow, 'style', 'padding-left: 15px;');

          this.renderer.addClass(fullScreenButton, 'col-sm-2');
          this.renderer.setAttribute(fullScreenButton, 'style', 'display: inline-table; padding-left: 0px; padding-right: 0px; padding-top: 1px;');

          this.renderer.addClass(expandButton, 'col-sm-2');
          this.renderer.setAttribute(expandButton, 'style', 'display: inline-table; padding-left: 0px; padding-right: 0px; padding-top: 1px;');

          let htmlTemplate = `
            <button type="submit" class="btn-resize button btn btn-success btn-sm" (click)="onSubmitClick()" style="padding: .625rem 1rem !important; border-radius: 0.4rem !important;">
              <i class="fas fa-expand"></i>
            </button>
          `;

          let htmlTemplate2 = `
            <button type="submit" class="btn-resize button btn btn-success btn-sm" (click)="onSubmitClick()" style="padding: .625rem 1rem !important; border-radius: 0.4rem !important;">
              <i class="fas fa-expand-alt"></i>
            </button>
          `;

          this.renderer.setProperty(fullScreenButton, 'innerHTML', htmlTemplate);
          this.renderer.setProperty(expandButton, 'innerHTML', htmlTemplate2);

          this.renderer.listen(fullScreenButton, 'click', (event) => {
            EventEmitterService.get('resizePbi').emit(true);
          });

          this.renderer.listen(expandButton, 'click', (event) => {
            if (this.isfullscreen) {

              this.closefullscreen();

              let template = `
              <button type="submit" class="btn-resize button btn btn-success btn-sm" (click)="onSubmitClick()" style="padding: .625rem 1rem !important; border-radius: 0.4rem !important;">
              <i class="fas fa-expand-alt"></i>
              </button>
              `;

              this.renderer.setProperty(expandButton, 'innerHTML', template);

            } else {

              this.openfullscreen();

              let template = `
              <button type="submit" class="btn-resize button btn btn-success btn-sm" (click)="onSubmitClick()" style="padding: .625rem 1rem !important; border-radius: 0.4rem !important;">
              <i class="fas fa-compress-alt"></i>
              </button>
              `;

              this.renderer.setProperty(expandButton, 'innerHTML', template);
            }
          });

          this.renderer.appendChild(divRow, expandButton);
          this.renderer.appendChild(divRow, fullScreenButton);

          this.renderer.appendChild(mainContainer, divRow);
        }

      } else {
        let btn = document.querySelectorAll('.btn-resize');
        btn.forEach(button => { button.remove() });
      }
    });
  }

  openfullscreen() {

    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }

    if (this.isSideBarExpanded) {
      this.sidebarService.toggle(true, 'menu-sidebar');
      this.layoutService.changeLayoutSize();
    }

    this.isfullscreen = true;

    if ((window.innerHeight + window.scrollY + 23) >= document.body.offsetHeight) {
      EventEmitterService.get('bottomScrolled').emit(true);
    } else {
      EventEmitterService.get('bottomScrolled').emit(false);
    }

  }

  closefullscreen() {

    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };

    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }

    if (!this.isSideBarExpanded) {
      this.sidebarService.toggle(false, 'menu-sidebar');
      this.layoutService.changeLayoutSize();
    }

    this.isfullscreen = false;

    if ((window.innerHeight + window.scrollY + 23) >= document.body.offsetHeight) {
      EventEmitterService.get('bottomScrolled').emit(true);
    } else {
      EventEmitterService.get('bottomScrolled').emit(false);
    }

  }

  ngOnDestroy() {
    this.alive = false;
  }
}
