import { MokaService } from '../../../services/moka.service';
import { Component, OnInit, Input, ViewEncapsulation, HostListener, ElementRef, EventEmitter, Output } from '@angular/core';
import { ToasterService, Toast, BodyOutputType } from 'angular2-toaster';



@Component({
  selector: 'ngx-suggestions',
  templateUrl: './suggestions.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./suggestions.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },

})
export class SuggestionsComponent implements OnInit {

  @Input() suggestions
  @Output() closeSuggestions = new EventEmitter<boolean>()
  @Output() refresh = new EventEmitter()
  loading: boolean = false
  buildingCount = new Map<string, number>();

  clickCounter: number = 0

  buildingList: string[] = [];
  filteredSuggestions: any;


  constructor(private _eref: ElementRef,
    private serverApi: MokaService,
    private toasterService: ToasterService) {

  }

  ngOnInit() {
    this.filterBuildings();
  }



  /**
   * {
    "status": 0,
    "activeSuggestions": [
        {
            "_id": "647a2b67b19c7a4b721f45e7",
            "commandList": [
                {
                    "id": "sys_LCSTPL11CM01QDFL01_C002CMD",
                    "status": "on",
                    "from_qr": true,
                    "text": "Ligar Comando",
                    "systemName": "Comando"
                },
                {
                    "id": "sys_LCSTPL11CM01QDFL01_C002STT",
                    "status": "off",
                    "from_qr": true,
                    "text": "Desligar Estado",
                    "systemName": "Estado"
                }
            ],
            "path": "LCS LINK > 11º ANDAR > Administrativo",
            "environment": "LCSTPL11_ADMINISTRATIVO",
            "userid": "6474e7fe92b826d9620f82a4",
            "username": "Anônimo_LCSTPL11_ADMINISTRATIVO",
            "suggestion": {
                "environmentTempSuggest": 30,
                "userComfortSuggest": [
                    22,
                    29,
                    28,
                    30,
                    30
                ],
                "userIntervalComfortMaxSuggest": 31,
                "userIntervalComfortMinSuggest": 27,
                "userTempComfortSuggest": 29
            },
            "active": true,
            "date": "2023-06-02T17:48:22.936Z",
            "approved": false,
            "location": [
                {
                    "_id": "63c6c0976f53c080f34e9f83",
                    "environment": "LCSTPL11_ADMINISTRATIVO",
                    "environment_name": "Administrativo",
                    "floor": "LCSTPL11",
                    "floor_name": "11º ANDAR",
                    "building": "LCS",
                    "building_name": "LCS LINK",
                    "local": "Paulista",
                    "__v": 0
                }
            ]
        },
    ]
}
   */

  filterBuildings() {
    for (let sug of this.suggestions.activeSuggestions) {
      if (!this.buildingList.includes(sug.location[0].building_name)) {
        this.buildingList.push(sug.location[0].building_name);
      }
    }

    for (let build of this.buildingList) {
      this.buildingCount.set(build, 0);
    }
    for (let sug of this.suggestions.activeSuggestions) {
      this.buildingCount.set(sug.location[0].building_name, this.buildingCount.get(sug.location[0].building_name) + 1);
    }

    this.buildingList.sort((a, b) => {
      if (this.buildingCount.get(a) > this.buildingCount.get(b))
        return -1;

      if (this.buildingCount.get(a) < this.buildingCount.get(b))
        return 1;

      return 0;
    }
    );

  }

  suggestionFilter(building) {
    return this.suggestions.activeSuggestions.filter((sug) => sug.location[0].building_name == building);
  }

  onClick(event) {

    //verificar se é o primeiro click. Se não for, aí fecha a lista de insights
    if (this.clickCounter > 0) {
      if (!this._eref.nativeElement.contains(event.target)) {
        this.closeSuggestions.emit()
      }
    }
    else {
      this.clickCounter++
    }
  }



  dateConvert(date_att) {
    const date = new Date(date_att);
    let day = date.getDate().toString();
    if (date.getDate() < 10) {
      day = "0" + day;
    }
    let month = date.getMonth().toString();
    if (date.getMonth() < 10) {
      month = "0" + month;
    }
    let minutes = date.getMinutes().toString();
    if (date.getMinutes() < 10) {
      minutes = "0" + minutes;
    }
    return day + '/' + month + ' às ' + date.getHours() + ':' + minutes;
  }

  approveSuggestion(approval) {
    this.loading = true;
    let serverResponse;
    this.serverApi.approveSuggestion(approval).then(data => {
      serverResponse = data;
      if (serverResponse.status == 1) {
        this.showToast("error", "Erro", "Occoreu algum erro ao aprovar a solicitação.");
      }
      else {
        this.showToast("success", "Sucesso", "Solicitação aprovada com sucesso.");
      }
      this.loading = false;
      this.refresh.emit();
    });
  }

  reproveSuggestion(approval) {
    this.loading = true;
    let serverResponse;
    this.serverApi.reproveSuggestion(approval).then(data => {
      serverResponse = data;
      if (serverResponse.status == 1) {
        this.showToast("error", "Erro", "Occoreu algum erro ao reprovar a solicitação.");
      }
      else {
        this.showToast("success", "Sucesso", "Solicitação reprovada com sucesso.");
      }
      this.loading = false;
      this.refresh.emit();
    });
  }





  private showToast(type, title, body) {

    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };

    this.toasterService.popAsync(toast);
  }
}
