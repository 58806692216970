import { Component, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators/takeWhile';
import { EventEmitterService } from '../../../services/event-emitter.service';

@Component({
  selector: 'external-layout',
  styleUrls: ['./external.layout.scss'],
  template: `
  <nb-layout [center]="true">

    <nb-layout-header fixed class="header-top">
      <external-header class="header" 
      [ngStyle]="{'background-image': this.headerBackground}"></external-header>
    </nb-layout-header>

    <nb-layout-column class="main-content">
      <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>

  </nb-layout> 

  <nb-layout-footer fixed>
      <ngx-footer class="footer" 
      [ngStyle]="{'background': this.footerBackground}"></ngx-footer>
  </nb-layout-footer>
  `,
})
export class ExternalLayoutComponent implements OnDestroy {

  private alive = true;

  currentTheme: string;

  headerBackground: string
  footerBackground: string

  constructor(protected themeService: NbThemeService) {
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
    });
    EventEmitterService.get('headerStyles').subscribe(data => this.changeHeaderStyle(data));
    EventEmitterService.get('footerStyles').subscribe(data => this.changeFooterStyle(data));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  changeHeaderStyle(header) {
    if (header && header != {}) {
      if (header.styles &&
        header.styles.primary_color &&
        header.styles.secondary_color) {
        this.headerBackground = "linear-gradient(to right, " + header.styles.primary_color + ", " + header.styles.secondary_color + ")"
      }
    }
  }

  changeFooterStyle(footer) {
    if (footer && footer != {}) {
      if (footer.background_color) {
        this.footerBackground = footer.background_color
      }
    }
  }
}
