import { EventEmitterService } from './event-emitter.service';
import { Injectable } from '@angular/core';
import { Http, Headers, ResponseContentType } from '@angular/http';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from '../auth';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class MokaService {
  public baseEndpoint = environment.baseURL;
  private headers;
  private downloadHeaders;
  private pdfHeaders;

  locals: any;
  environments: any;
  matching: any;
  mreport: any;
  allCharts: any;
  tokenExpTime: any;

  constructor(private http: Http, private router: Router, private authService: NbAuthService) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        let response = token.getPayload();
        this.tokenExpTime = response.exp;
      }
    });
  }

  checkAuth(token) {
    if (!token) {
      this.router.navigate(['auth/logout']);
      EventEmitterService.get('closeCorpModal').emit();
      return false;
    }

    // let currentTime = moment().valueOf();
    let tokenTime = moment.utc(this.tokenExpTime);

    if (moment().isAfter(tokenTime)) {
      this.router.navigate(['auth/logout']);
      EventEmitterService.get('closeCorpModal').emit();
      return false;
    }

    return true;
  }

  setHeaders() {
    return new Promise((resolve) => {
      let data;
      data = localStorage.getItem('auth_app_token');

      if (!this.checkAuth(data)) resolve(this.headers);

      data = JSON.parse(data);
      data.value = 'Bearer ' + data.value;
      //  if (!local) data.value = 'Bearer ' + data.value;
      // else if (local) data.value = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiI1YzgwMWY2NjcwNzUxODU3NTk4N2YyNjYiLCJleHAiOjE1NzkzMjk1ODQ2ODB9.yIdbpuulN5FjjptqEDF_CLN8UxZ2ifBOPqMC5vb2Dyg';

      this.headers = new Headers();
      this.headers.append('Authorization', data.value);
      this.headers.append('Accept', 'application/vdn.softix.api-v2+json');
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Cache-Control', 'no-cache');
      this.headers.append('Pragma', 'no-cache');
      this.headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

      this.downloadHeaders = new Headers();
      this.downloadHeaders.append('Authorization', data.value);
      this.downloadHeaders.append('Accept', '*/*');
      this.downloadHeaders.append('Content-Type', 'multipart/form-data');
      this.downloadHeaders.append('Cache-Control', 'no-cache');
      this.downloadHeaders.append('Pragma', 'no-cache');
      this.downloadHeaders.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

      resolve(this.downloadHeaders);
      resolve(this.headers);
    });
  }

  getLocals() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/local/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              this.locals = data.json();
              resolve(this.locals);
            },
            (error) => {
              if (error.status === 401 || error.status === 403) {
                this.router.navigate(['auth/logout']);
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getEnvironments() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/environment/listAll', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              this.environments = data.json();
              resolve(this.environments);
            },
            (error) => {
              if (error.status === 401 || error.status === 403) {
                this.router.navigate(['auth/logout']);
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getBuildings() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/mb/napp/user/building/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              this.environments = data.json();
              resolve(this.environments);
            },
            (error) => {
              if (error.status === 401 || error.status === 403) {
                this.router.navigate(['auth/logout']);
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getEnvironmentsByBuildingId(buildingId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/register/building/getEnvironment/' + buildingId, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setBuildingEnvironment(environment) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/register/building/setEnvironment',
            { environment: environment },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getMatchings(environment) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/mia/matching/getByEnvironment/' + environment, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              this.baseEndpoint = data.json();
              resolve(this.matching);
            },
            (error) => {
              if (error.status === 401 || error.status === 403) {
                this.router.navigate(['auth/logout']);
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getMReport(type, environment, id = null) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/mreport/get',
            { type: type, environment: environment, instanceid: id },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              this.mreport = data.json();
              resolve(this.mreport);
            },
            (error) => {
              if (error.status === 401 || error.status === 403) {
                this.router.navigate(['auth/logout']);
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  sendCommand(type, contextid, object, state) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/command/' + object + '/' + state,
            { type: type, contextid: contextid },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403) {
                this.router.navigate(['auth/logout']);
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getAllCharts(environment) {
    return new Promise((resolve) => {
      this.setHeaders().then((data) => {
        this.http
          .get(this.baseEndpoint + '/api/charts/all/' + environment, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              this.allCharts = data.json();
              // this.allCharts.timeUpdate = this.getCurrentTime();
              resolve(this.allCharts);
            },
            (error) => {
              if (error.status === 401 || error.status === 403) {
                this.router.navigate(['auth/logout']);
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getCharts(environment, charttype, type = null, instanceid = null) {
    return new Promise((resolve) => {
      let path;
      this.setHeaders().then((data) => {
        if (type == 'sensor') {
          path = '/api/sensor/chart';
        } else if (type == 'system') {
          path = '/api/charts/system/get';
        } else if (type == 'efficiency') {
          path = '/api/charts/efficiency/get';
        }
        this.http
          .post(
            this.baseEndpoint + path,
            { environment: environment, charttype: charttype, instanceid: instanceid },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              // this.charts = data;
              // this.charts.timeUpdate = this.time();
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  refreshDataSet(datasetId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/refresh', { dataset: datasetId }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500, message: error });
              }
            }
          );
      });
    });
  }

  getDailyRefreshes(dataSetId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/getDailyRefreshes/' + dataSetId, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getAllRefreshHistory(dataset_id) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/refreshHistory', { dataset: dataset_id }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getRefreshHistoryByQuantity(dataset_id, quantity) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/powerbi/refreshHistory',
            { dataset: dataset_id, quantity: quantity },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getBuildingList(local) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/building/list/' + local, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              //   this.buildingList = data.json();
              //   this.buildingList.timeUpdate = this.time();
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getBuildingListAll() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/building/listAll', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getPowerBiAcessToken() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/auth', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getPowerBiResource(menu) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/getUserResources/' + menu, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400 || error.status === 404) {
                resolve({ erro: 'true', status: 1 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 1 });
              }
            }
          );
      });
    });
  }

  getResources() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/getResources', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 400 });
              }
            }
          );
      });
    });
  }

  //Serve para atualizar ou criar um novo resource
  setResource(resource) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/setResource', { resource: resource }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 400 });
              }
            }
          );
      });
    });
  }

  removeResource(resourceId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/removeResource', { id: resourceId }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 400 });
              }
            }
          );
      });
    });
  }

  getCredentialData() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/getCredentials', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setCredentialData(credentialData) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/setCredential', { credential: credentialData }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getBuildingTable(buildingId, year) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/register/building/getTable', { id: buildingId, year: year }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setBuildingTable(buildingTable) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/register/building/setTable',
            { buildingTable: buildingTable },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getBuildingSchedule(buildingId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + `/api/register/building/getSchedule?buildingId=${buildingId}`, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setBuildingSchedule(schedule) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/register/building/setSchedule',
            { buildingSchedule: schedule },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getBuildingAddData(buildingId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + `/api/register/building/getAddData?buildingId=${buildingId}`, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setBuildingAddData(buildingDetails, building, supplyType) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/register/building/setAddData',
            { buildingDetails: buildingDetails, building: building, supply_type: supplyType },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getCommLevels() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/comm/level/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  findUnregisteredById(groupId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + `/api/mb/telegram/group/chat/${groupId}`, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  createNewCommGroup(group) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/group/register', { group: group }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  createNewTelegramCommGroup(group) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/mb/telegram/group/create', { group }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  createNewCommTelegramGroup(group) {
    group.energyBox = true;
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/group/register', { group: group }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getActiveCommGroups() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/mb/telegram/group', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getActiveCommTelegramGroups() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/comm/group/list-box', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getActiveCommWppGroups() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/comm/group/list-wpp', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setTelegramCommGroup(group) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .put(this.baseEndpoint + '/api/mb/telegram/group/update', { group }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setCommGroup(group) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/group/update', { group: group }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setCommTelegramGroup(group) {
    group.energyBox = true;
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/group/update', { group: group }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  removeActiveCommGroup(group) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/group/delete', { group: group }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  removeActiveTelegramCommGroup(group) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + `/api/mb/telegram/group/delete?groupid=${group.group_id}`, { data: {} }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  removeActiveCommTelegramGroup(group) {
    group.energyBox = true;
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/group/delete', { group: group }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  createNewBotConfig(bot) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/bot/register', { bot: bot }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getBotConfigs() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/comm/bot/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  removeBotConfig(bot) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/bot/delete', { bot: bot }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setBotConfig(bot) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/comm/bot/update', { bot: bot }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getBotCategories() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/comm/category/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getRegisteredUsers() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/mb/napp/user/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getUserEnvironments(userId: string) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + `/api/mb/napp/user/environments/${userId}`, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setRegisteredUser(user, environmentList, deleteUserEnvironments, resourceList, commLevelList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .put(
            this.baseEndpoint + '/api/mb/napp/user/update',
            { user, buildingList: environmentList, deleteUserEnvironments },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  createNewUser(user, environmentList, resourceList, commLevelList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/mb/napp/user/create',
            { user: user, buildingList: environmentList },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  removeRegisteredUser(user) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/register/user/delete', { user: user }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getLoggedUser() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/logged/info', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setUserCorpAsActive(corpId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/usercorp/active/' + corpId, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  setInsightFeedback(insight, feedback) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/insight/feedback', { insight: insight, feedback: feedback }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  // getActiveInsights() {
  //   return new Promise(resolve => {
  //     this.setHeaders().then(() => {
  //       this.http.get(this.baseEndpoint + '/api/insight/listAll', { headers: this.headers })
  //         .toPromise().then(data => {
  //           resolve(data.json());
  //         }
  //           , error => {
  //             if (error.status === 401 || error.status === 403 || error.status === 400) {
  //               resolve({ erro: 'true', status: 400 });
  //             } else if (error.status === 500) {
  //               resolve({ erro: 'true', status: 500 });
  //             }
  //           })
  //     });
  //   });
  // }

  getActiveInsights(environmentList?: string) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + `/api/mb/napp/insights/list/active?environmentList=${environmentList || ''}`, {
            headers: this.headers,
          })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getActiveSuggestions() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/mb/napp/adaptative/list/active', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  approveSuggestion(approval) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/mb/napp/adaptative/approve',
            { data: { approval: approval } },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  reproveSuggestion(approval) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/mb/napp/adaptative/reprove',
            { data: { approval: approval } },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getInsightCategoryList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/insight/getCategoryList', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getMenus() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/getMenuList', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  getMenuById(menuId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/getMenu/' + menuId, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400 || error.status === 404) {
                resolve({ erro: 'true', status: 1 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 1 });
              }
            }
          );
      });
    });
  }

  getUserMenus() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/getUserMenuList', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  //Serve para atualizar ou criar um novo menu
  setMenu(menu) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/setMenu', { menu: menu }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 400 });
              }
            }
          );
      });
    });
  }

  removeMenu(menuId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/removeMenu', { id: menuId }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  updateOrderMenu(menuList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/updateOrderMenu', { menuList: menuList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 400 });
              }
            }
          );
      });
    });
  }

  getVisual(visualName) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/helper/getVisual', { name: visualName }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getSalesforceToken() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/salesforce/auth', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setCase(access_token, token_type, lstCase) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/salesforce/case/new',
            { access_token: access_token, token_type: token_type, lstCase: lstCase },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getResourceHeightList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/powerbi/getResourceHeightList', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setResourceHeight(resourceHeight) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/powerbi/setResourceHeight',
            { resourceHeight: resourceHeight },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  removeResourceHeight(resourceHeightId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/powerbi/removeResourceHeight', { id: resourceHeightId }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getItemsSmartClass() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/smartclass/items/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  createItemSmartClass(item) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/items/new', { item: item }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setItemSmartClass(item) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/items/update', { item: item }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  removeItemSmartClass(item) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/items/delete', { item: item }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getServicesSmartClass() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/smartclass/services/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  createServiceSmartClass(service) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/services/new', { service: service }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setServiceSmartClass(service) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/services/update', { service: service }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  removeServiceSmartClass(service) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/services/delete', { service: service }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getAnswersSmartClass() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/smartclass/answers/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  createAnswerSmartClass(answer) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/answers/new', { answer: answer }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setAnswerSmartClass(answer) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/answers/update', { answer: answer }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  removeAnswerSmartClass(answer) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/smartclass/answers/delete', { answer: answer }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getThingsboardAccessToken(userType) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/thingsboard/auth/' + userType, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  createTicket() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/zendesk/ticket/new', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getTicket(ticketId) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/zendesk/ticket/get/' + ticketId, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setTarifa(tarifa) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/billoption/tarifa/set', { tarifa: tarifa }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getTarifaList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/billoption/tarifa/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  deleteTarifa(tarifa) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/billoption/tarifa/delete', { tarifa: tarifa }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }
  setBillConsumption(consumption) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + '/api/billoption/consumption/new',
            { new_consumption: consumption },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }
  updateBillConsumption(consumption) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/billoption/consumption/update', { consumption: consumption }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }
  deleteBillConsumption(consumption) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/billoption/consumption/delete', { consumption: consumption }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getBillConsumptionList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/billoption/consumption/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  createOperatingStandard(operation) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/operation/card/create', { operation: operation }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  updateOperatingStandard(operation) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/operation/card/update', { operation: operation }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getOperatingStandards() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/operation/card/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  removeOperatingStandard(operation) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/operation/card/delete', { operation: operation }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getOperatingStandardColors() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/operation/color/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getOperatingStandardPeriods() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/operation/period/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }
  getOperatingStandardSystems() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/operation/system/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  generateQRCode(templateJson) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/qrcode/generate', { templateJson: templateJson }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  generatePdf(qrIds) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/qrcode/pdf', { qrIds }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  openPdf(url) {
    window.open(this.baseEndpoint + url, "_blank");
  }

  getSavedQRCodeList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/qrcode/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  deleteSavedQRCode(_id) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/qrcode/remove', { _id: _id }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  updateSavedQRCode(_id, updatedQRCode) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/qrcode/update', { _id: _id, qrCode: updatedQRCode }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getSavedQRCodeById(_id) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/qrcode/getById/' + _id, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  saveQRCodeFormResponse(formResponse: Object) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/qrcode/formResponse/set', { formResponse: formResponse }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getSavedQRCodeByQrId(id) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/qrcode/getByQrId/' + id, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  // Public Call
  getPublicSavedQRCodeByQrId(id) {
    return new Promise((resolve) => {
      this.http
        .get(this.baseEndpoint + '/api/public/qrcode/getByQrId/' + id)
        .toPromise()
        .then(
          (data) => {
            resolve(data.json());
          },
          (error) => {
            if (error.status === 401 || error.status === 403 || error.status === 400) {
              resolve({ erro: 'true', status: 400 });
            } else if (error.status === 500) {
              resolve({ erro: 'true', status: 500 });
            } else if (error.status === 404) {
              resolve({ erro: 'true', status: 404 });
            }
          }
        );
    });
  }

  // Public Call
  savePublicQRCodeFormResponse(formResponse) {
    return new Promise((resolve) => {
      this.http
        .post(this.baseEndpoint + '/api/public/qrcode/formResponse/set', { formResponse: formResponse })
        .toPromise()
        .then(
          (data) => {
            resolve(data.json());
          },
          (error) => {
            if (error.status === 401 || error.status === 403 || error.status === 400) {
              resolve({ erro: 'true', status: 400 });
            } else if (error.status === 500) {
              resolve({ erro: 'true', status: 500 });
            } else if (error.status === 404) {
              resolve({ erro: 'true', status: 404 });
            }
          }
        );
    });
  }

  saveAnonymousQRCodeFormResponse(formResponse) {
    return new Promise((resolve) => {
      this.http
        .post(this.baseEndpoint + '/api/public/qrcode/formResponseAnonymous/set', { formResponse: formResponse })
        .toPromise()
        .then(
          (data) => {
            resolve(data.json());
          },
          (error) => {
            if (error.status === 401 || error.status === 403 || error.status === 400) {
              resolve({ erro: 'true', status: 400 });
            } else if (error.status === 500) {
              resolve({ erro: 'true', status: 500 });
            } else if (error.status === 404) {
              resolve({ erro: 'true', status: 404 });
            }
          }
        );
    });
  }

  getGeneralParamList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/param/general/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setGeneralParamList(paramList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/param/general/set', { paramList: paramList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setGeneralParamSensors(paramList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/param/general/set/sensors', { paramList: paramList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getBuildingTableParams(id, year) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/register/building/getTable', { id: id, year: year }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getEnvParametersList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/param/environment/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setEnvParametersList(envParamList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/param/environment/set', { environmentList: envParamList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getAlarmParametersList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/param/alarm/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getCommLevelList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/comm/level/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setAlarmParametersList(alarmParamList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/param/alarm/set', { alarmList: alarmParamList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getSystemParametersList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/param/system/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setSystemParametersList(systemParametersList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/param/system/set', { systemList: systemParametersList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getInsightParametersList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/param/insight/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  setInsightParametersList(insightParametersList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/param/insight/set', { insightList: insightParametersList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getCommandDeviceList(envid) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/device/command/get/' + envid, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getDeviceStatusList(deviceList) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/device/command/getStatusList', { deviceList: deviceList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getQRCodeApiList() {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/qrcode/api/list', { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getApiStructure(apiCode) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + '/api/qrcode/api/structure/' + apiCode, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  callDynamicUrl(url) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getBuildingsList() {
    const url = '/api/mb/ms/building/list';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getEnviromentList(params: string) {
    const url = '/api/mb/ms/environment/list?';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getSensorTypes(params: string) {
    const url = '/api/mb/ms/sensor/sensorTypesAir?';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getSensors(params: string) {
    const url = '/api/mb/ms/sensor/list?';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getHeatMapResource(params: string) {
    params = params ? params : '';

    let url = '/api/mb/ms/consumption/heatmap';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getBigNumbersInformations(params: string) {
    let url = '/api/mb/ms/consumption/bigNumbers';

    let sensorType = 'sensorType=energy';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params + '&' + sensorType, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getDailyChartsData(params) {
    let url = '/api/mb/ms/consumption/dailyChartsData';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getMonthlyChartData(params) {
    let url = '/api/mb/ms/consumption/monthlyChartData';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getAnalytics(params) {
    let url = '/api/mb/ms/analytics/miaAnalytics';
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', errorMessage: error._body, status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', errorMessage: error._body, status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', errorMessage: error._body, status: 404 });
              }
            }
          );
      });
    });
  }

  async getEnvironmentsList(path: string) {
    let url = '/api/mb/ms/environment/list';
    const build = '?buildingList=';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        if (path == 'Todos') {
          this.http
            .get(this.baseEndpoint + url, { headers: this.headers })
            .toPromise()
            .then(
              (data) => {
                resolve(data.json());
              },
              (error) => {
                if (error.status === 401 || error.status === 403 || error.status === 400) {
                  resolve({ erro: 'true', status: 400 });
                } else if (error.status === 500) {
                  resolve({ erro: 'true', status: 500 });
                } else if (error.status === 404) {
                  resolve({ erro: 'true', status: 404 });
                }
              }
            );
        } else {
          this.http
            .get(this.baseEndpoint + url + build + path, { headers: this.headers })
            .toPromise()
            .then(
              (data) => {
                resolve(data.json());
              },
              (error) => {
                if (error.status === 401 || error.status === 403 || error.status === 400) {
                  resolve({ erro: 'true', status: 400 });
                } else if (error.status === 500) {
                  resolve({ erro: 'true', status: 500 });
                } else if (error.status === 404) {
                  resolve({ erro: 'true', status: 404 });
                }
              }
            );
        }
      });
    });
  }

  getMonths() {
    const url = '/api/mb/dash/date/months';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getBigNumbersData(params: string) {
    let url = '/api/mb/ms/consumption/bigNumbers?';
    let sensorType = params.split('&')[2].split('=')[1];
    if (sensorType == 'external_humidity' || sensorType == 'external_temperature') {
      url = '/api/mb/dash/analytics/air/numbers?';
    }

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getAirHeatMapData(params: string) {
    let url = '/api/mb/ms/consumption/heatmap?';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url + params, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getDaysInterval() {
    const url = '/api/mb/ms/date/days';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getConsumptionManagerBalance(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/consumption/balance/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getConsumptionManagerBalanceApportionment(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/consumption/getApportionmentChart/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getConsumptionManagerBaseOfCalculation(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/consumption/calculationsBase/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getManagerConsumptionInsights(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/consumption/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  getManagerConsumptionMonthlyCharts(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/consumption/monthlyCharts/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getEfficiencyPredictTable(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/split/efficiency/prediction/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getEquipamentTableWithEfficiencyClassification(
    environment: string,
    start: string,
    end: string,
    floor: string,
    building: string
  ) {
    const url = `/api/mb/ms/manager/split/efficiency/classification/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getEfficiencyHistoryChart(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/split/efficiency/history/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getSplitEfficiencyInsights(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/split/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getAnsweredInsightsChartByCategories(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/chart/bar/answeredByCategories/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getAnsweredInsightsTableByCategories(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/table/created/categories/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getAnsweredInsightsTableByUsers(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/table/answeredByUsers/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getCreatedInsightsTableByWeek(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/table/created/weekly/?environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getCreatedInsightsTable(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/table/created/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getInsightsChartByMonth(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/chart/bar/byMonth/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getInsightsByCategories(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/table/categories/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getComfortInsightsTable(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/insghts/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getComfortTempreture(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/temperature/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getSolicitations(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/solicitation?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getUserMatchingAverage(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/user/matching/average/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getEnvironmentMatchingAverage(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/environment/matching/average/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getUserMatchingGraphic(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/user/matching/graphic/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getMatchingEnvironmentGraphic(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/environment/matching/graphic/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getUserMatchingTable(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/user/matching/table/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getMatchingEnvironmentTable(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/environment/matching/table/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getMatchingUserWeekly(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/user/matching/weekly/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getMatchingEnvironmentWeekly(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/environment/matching/weekly/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getPerformedVsApprovedSolicitations(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/solicitation/graphic/?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getWordCloud(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/insights/wordcount?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async getApiComunicationStatus() {
    const url = '/api/mt/api/status';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async activeApiComunication(active: boolean) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/mt/api/status/active', { active: active }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async activeQRCode(qrId: string, active: boolean) {
    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + '/api/qrcode/active', { qrId: qrId, active: active }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getFloorsList(building) {
    const url = `/api/mb/ms/floor/list?buildingList=${building}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getComfortLogTable(environment: string, start: string, end: string, floor: string, building: string) {
    const url = `/api/mb/ms/manager/comfort/suggestion/log/table?buildingList=${building}&environmentList=${environment}&floorList=${floor}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getOneApprovalRequestByCode(code: string) {
    const url = `/api/mia/approvalRequest/code/${code}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getSensorsParamsList() {
    const url = '/api/param/sensor/list';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async setSensorsParams(sensorList: any) {
    const url = '/api/param/sensor/set';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + url, { sensorList }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getAllNobreaks(floorList: string, environmentList: string) {
    const url = `/api/mb/ms/nobreak/list?&floorList=${floorList}&environmentList=${environmentList}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getNobreakBigNunbers(
    building: string,
    floorList: string,
    environmentList: string,
    nobreakList: string,
    start: string,
    end: string
  ) {
    const url = `/api/mb/ms/nobreak/bigNumbers?buildingList=${building}&floorList=${floorList}&environmentList=${environmentList}&nobreakList=${nobreakList}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getAlarmsByCategories(
    building: string,
    floorList: string,
    environmentList: string,
    nobreakList: string,
    start: string,
    end: string
  ) {
    const url = `/api/mb/ms/nobreak/alarms/category?buildingList=${building}&floorList=${floorList}&environmentList=${environmentList}&nobreakList=${nobreakList}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getNobreakEvents(
    building: string,
    floorList: string,
    environmentList: string,
    nobreakList: string,
    start: string,
    end: string
  ) {
    const url = `/api/mb/ms/nobreak/events?buildingList=${building}&floorList=${floorList}&environmentList=${environmentList}&nobreakList=${nobreakList}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getEventTimeVSBattetyVoltage(
    building: string,
    floorList: string,
    environmentList: string,
    nobreakList: string,
    start: string,
    end: string
  ) {
    const url = `/api/mb/ms/nobreak/graphic?buildingList=${building}&floorList=${floorList}&environmentList=${environmentList}&nobreakList=${nobreakList}&start=${start}&end=${end}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getWhatsappQR() {
    const url = '/api/mb/napp/whatsapp/qrcode';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getGroupings() {
    const url = '/api/comm/grouping/list';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async createGrouping(grouping: any) {
    const url = '/api/comm/grouping/create';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + url, { grouping }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async updateGrouping(grouping: any) {
    const url = '/api/comm/grouping/update';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + url, { grouping }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async deleteGrouping(groupingId: string) {
    const url = '/api/comm/grouping/delete';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + url, { groupingId }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getWppGroupInfo(groupId: string) {
    const url = `/api/mb/napp/whatsapp/group/${groupId}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async sendWppMessage(messageBody: any) {
    const url = '/api/mb/napp/whatsapp/send/message';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + url, { group: messageBody.group, message: messageBody.message }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async wppLogout() {
    const url = '/api/mb/napp/whatsapp/logout';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .put(this.baseEndpoint + url, { data: {} }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async uploadQRFile(file: any, qrId: string, token: string) {
    const url = '/api/qrcode/file/upload';

    return new Promise((resolve) => {
      this.http
        .post(this.baseEndpoint + url, {
          data: {
            qrId,
            token,
            file,
          },
        })
        .toPromise()
        .then(
          (data) => {
            resolve(data.json());
          },
          (error) => {
            if (error.status === 401 || error.status === 403 || error.status === 400 || error.status === 413) {
              try {
                resolve({
                  erro: 'true',
                  status: error.status,
                  message: JSON.parse(error._body).message || 'Erro ao enviar arquivo',
                });
              } catch {
                resolve({ erro: 'true', status: error.status, message: '' });
              }
            } else if (error.status === 500) {
              resolve({ erro: 'true', status: 500 });
            }
          }
        );
    });
  }

  async deleteQRFile(qrId: string, token: string, fileId: string) {
    const url = '/api/qrcode/file/delete';

    return new Promise((resolve) => {
      this.http
        .post(this.baseEndpoint + url, {
          data: {
            qrId,
            token,
            fileId,
          },
        })
        .toPromise()
        .then(
          (data) => {
            resolve(data.json());
          },
          (error) => {
            if (error.status === 401 || error.status === 403 || error.status === 400 || error.status === 413) {
              resolve({ erro: 'true', status: 400, message: JSON.parse(error._body).message || '' });
            } else if (error.status === 500) {
              resolve({ erro: 'true', status: 500 });
            }
          }
        );
    });
  }

  async listAllDevices() {
    const url = '/api/param/device/list';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async deleteSensorParameter(sensors: any[]) {
    const url = '/api/param/sensor/delete';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + url,
            {
              sensors,
            },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async deleteSystemParameter(systems: any[]) {
    const url = '/api/param/system/delete';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(
            this.baseEndpoint + url,
            {
              systems,
            },
            { headers: this.headers }
          )
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              } else if (error.status === 404) {
                resolve({ erro: 'true', status: 404 });
              }
            }
          );
      });
    });
  }

  async listImportedFiles() {
    const url = '/api/mb/lds/importing/list';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async downloadImportedList(fileId: string) {
    const url = `/api/mb/lds/importing/download/${fileId}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.downloadHeaders, responseType: ResponseContentType.Blob })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async saveLinkTree(title: string, subtitle: string, btnTemplate: any) {
    const url = `/api/ms/qrcode/linktree/create`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .post(this.baseEndpoint + url, { title, subtitle, btnTemplate }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getLinkTree() {
    const url = '/api/ms/qrcode/linktree/get';

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .get(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400 || error.status === 404) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async getUniqueLinkTree(id: string) {
    const url = `/api/ms/qrcode/linktree/get/unique/${id}`;

    return new Promise((resolve) => {
      this.http
        .get(this.baseEndpoint + url)
        .toPromise()
        .then(
          (data) => {
            resolve(data.json());
          },
          (error) => {
            if (error.status === 401 || error.status === 403 || error.status === 400 || error.status === 404) {
              resolve({ erro: 'true', status: 400 });
            } else if (error.status === 500) {
              resolve({ erro: 'true', status: 500 });
            }
          }
        );
    });
  }

  async updateLinkTree(id: string, linkTreeUpdate: any) {
    const url = `/api/ms/qrcode/linktree/update/${id}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .put(this.baseEndpoint + url, { linkTreeUpdate }, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }

  async deleteLinkTree(id: string) {
    const url = `/api/ms/qrcode/linktree/delete/${id}`;

    return new Promise((resolve) => {
      this.setHeaders().then(() => {
        this.http
          .delete(this.baseEndpoint + url, { headers: this.headers })
          .toPromise()
          .then(
            (data) => {
              resolve(data.json());
            },
            (error) => {
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                resolve({ erro: 'true', status: 400 });
              } else if (error.status === 500) {
                resolve({ erro: 'true', status: 500 });
              }
            }
          );
      });
    });
  }
}
