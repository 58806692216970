import { environment } from './../../../../environments/environment.prod';
import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '../../../auth';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { LayoutService } from '../../../@core/data/layout.service';
import { EventEmitterService } from '../../../services/event-emitter.service';
import { MokaService } from '../../../services/moka.service';
import { ToasterConfig, ToasterService, BodyOutputType, Toast } from 'angular2-toaster';
import { ObservablesService } from '../../../services/observables.service';
import { CorpColorService } from '../../../@core/data/corp-color.service';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() position = 'normal';
  insights: any = [];
  suggestions: any = [];
  categoryList: any = [];
  showInsights = false;
  showSolicitations = false;
  loading: boolean = false
  refreshInterval: any
  counter: number = 600
  user: any = {};
  hasCorp: boolean
  corp: any = {}
  corps: any = []
  private sub: any;

  @ViewChild('userMessage') userMessage: ElementRef;
  @ViewChild('chatButton') chatButton: ElementRef;
  chatContent: any = [];
  helperText: any;
  helperTitle: any;
  helperId: any;
  chatText: string = "Com análise dos dados este gráfico apresenta um comportamento observado dentro da normalidade.  Nesta análise foram considerado os valores de XX e Meta MM.";
  showAnaliseButton: boolean = false;

  userMenu = [{ title: 'Log out', link: '/auth/logout' }];

  toasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 5000,
    newestOnTop: true,
    tapToDismiss: true,
    preventDuplicates: false,
    showCloseButton: true,
    animation: 'flyRight',
    limit: 5,
  });

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    private corpColorService: CorpColorService,
    private serverApi: MokaService,
    private toasterService: ToasterService,
    public observablesService: ObservablesService,
    private _eref: ElementRef) {

    EventEmitterService.get('corpChange').subscribe(data => this.changeUserLogo(data));

    this.observablesService.getCurrent('helperText').subscribe((data) => {
      this.helperText = data;
    });

    this.observablesService.getCurrent('helperId').subscribe((data) => {
      this.helperId = data;
    });

    this.observablesService.getCurrent('helperTitle').subscribe((data) => {
      this.helperTitle = data;
    });

    this.observablesService.getCurrent('helperWindow').subscribe((data) => {
      if (data === true) {
        this.chatButton.nativeElement.click();
      }
    });
  }

  ngOnInit() {
    this.user.name = JSON.parse(localStorage.getItem('username'));
    this.user.picture = JSON.parse(localStorage.getItem('userImg'));
    if (localStorage.getItem('corp')) {
      this.corps = JSON.parse(localStorage.getItem('usercorps'))
      this.corp = JSON.parse(localStorage.getItem('corp'));
      this.hasCorp = true
    }
    this.getInsights();
    this.getInsightCategoryList();
    this.getSuggestions();
  }

  ngAfterViewInit() {
    this.enableInsightsRefresh()
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.disableInsightsRefresh()
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    if ((window.innerHeight + window.scrollY + 23) >= document.body.offsetHeight) {
      EventEmitterService.get('bottomScrolled').emit(true);
    } else {
      EventEmitterService.get('bottomScrolled').emit(false);
    }

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  disableInsightsRefresh() {
    clearInterval(this.refreshInterval)
  }

  changeUserLogo(_corp) {
    this.corps = _corp
    if (localStorage.getItem('corp')) {
      this.corp = JSON.parse(localStorage.getItem('corp'));
      this.hasCorp = true
    }
    EventEmitterService.get('updateCorp').emit()
  }

  onInsightsClick() {
    this.showInsights = !this.showInsights
    this.showInsights === false ? this.enableInsightsRefresh() : this.disableInsightsRefresh()
  }

  onSolicitationsClick() {
    this.showSolicitations = !this.showSolicitations;
    this.showSolicitations === false ? this.enableInsightsRefresh() : this.disableInsightsRefresh()
  }

  closeSolicitations() {
    this.showSolicitations = false
    this.enableSuggestionsRefresh()
  }

  closeInsights() {
    this.showInsights = false
    this.enableInsightsRefresh()
  }

  getInsightCategoryList() {

    this.serverApi.getInsightCategoryList().then(data => {
      let serverResponse: any = data;

      if (serverResponse.status === 0) {
        this.categoryList = serverResponse.insightsCategoryList;

        // this.categoryList.map((category) => { category.selected = true; category.quantity = 0 });
      }
    });
  }

  getInsights() {
    this.loading = true;
    let serverResponse;
    this.serverApi.getActiveInsights().then(data => {
      serverResponse = data;
      if (serverResponse.status === 1) {
        this.showToast("error", "Erro", "Ocorreu algum erro ao carregar os insights ativos.")
      }
      else {
        this.insights = serverResponse.activeInsights;
      }
      this.loading = false;
    })
  }

  getSuggestions() {
    this.loading = true;
    let serverResponse;
    this.serverApi.getActiveSuggestions().then(data => {
      serverResponse = data;
      if (serverResponse.status === 1) {
        this.showToast("error", "Erro", "Ocorreu algum erro ao carregar as sugestões ativas.")
      }
      else {
        this.suggestions = serverResponse;
      }
      this.loading = false;
    })
  }

  enableInsightsRefresh() {
    this.refreshInterval = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter === 0) {
        this.counter = 600
        this.getInsights()
      }
    }, 1000)
  }

  enableSuggestionsRefresh() {
    this.refreshInterval = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter === 0) {
        this.counter = 600
        this.getSuggestions()
      }
    }, 1000)
  }

  private showToast(type, title, body) {

    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };

    this.toasterService.popAsync(toast);
  }

  /*
   *  Helper
   */

  setChatText(text) {
    this.chatText = text;
  }

  changeAnaliseButton(state) {

    var thisReference = this;

    setTimeout(function () {
      thisReference.showAnaliseButton = state;
    }, 10);
  }

  sendUserMessage() {
    let userMessage = this.userMessage.nativeElement.value;


    this.chatContent.push({ message: userMessage, type: 'user' });
  }
}
