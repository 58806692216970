import { Component, NgZone } from '@angular/core';
import * as data from '../../../../git-version.json'
import { EventEmitterService } from '../../../services/event-emitter.service';
@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="col-md-5 moka-logo"> 
      <span class="created-by" [ngStyle]="{'color': this.fontColor}"> 
        <img [src]="this.logo" alt="moka icon" style="height:50px;">
      </span>
    </div>
    <div class="col-md-7 moka-version" [ngStyle]="{'color': this.fontColor}"> 
      <span class="moka-version" style="font-weight: bold;" 
            [ngStyle]="{'color': this.fontColor}"
            *ngIf="!this.text"> Moka Web ${new Date().getFullYear()}.</span>
      <span *ngIf="!this.text"> Desenvolvido e mantido pela Moka Mind. {{git.tag}}</span>
      <span *ngIf="this.text"> {{ this.text }}</span>
    </div>
  `,
})
export class FooterComponent {
  public git = data;

  logo: string = "assets/images/moka/moka-mind-logo-270x83.png"
  fontColor: string = "#ffffff"
  text: string

  constructor() {
    EventEmitterService.get('footerStyles').subscribe(data => this.changeFooterStyle(data));
  }

  changeFooterStyle(footer) {
    if (footer && footer != {}) {
      if (footer.icon) {
        this.logo = footer.icon
      }
      if (footer.text) {
        this.text = footer.text
      }
      if (footer.font_color) {
        this.fontColor = footer.font_color
      }
    }
  }
}