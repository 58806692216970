import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
 
@Injectable({ providedIn: 'root' })
export class ObservablesService {

    /*The BehaviorSubject has the characteristic that it stores the “current” value. 
    This means that you can always directly get the last emitted value from the BehaviorSubject. */
    local = new BehaviorSubject<any>(null);
    buildingsList = new BehaviorSubject<any>(null);
    building = new BehaviorSubject<any>(null);
    helperText = new BehaviorSubject<any>(null);
    helperTitle = new BehaviorSubject<any>(null);
    helperWindow = new BehaviorSubject<any>(null);
    helperId = new BehaviorSubject<any>(null);
 
    clearCurrent(type){
        if (type === 'local') this.local.next;
        if (type === 'buildingList') this.buildingsList.next;
        if (type === 'building') this.building.next;
        if (type === 'helperText') this.helperText.next('');
        if (type === 'helperTitle') this.helperTitle.next('');
        if (type === 'helperWindow') this.helperWindow.next;
        if (type === 'helperId') this.helperId.next;
    }
    
    getCurrent(type): Observable<any> {
        if (type === 'local') return this.local.asObservable();
        if (type === 'buildingsList') return this.buildingsList.asObservable();
        if (type === 'building') return this.building.asObservable();
        if (type === 'helperText') return this.helperText.asObservable();
        if (type === 'helperTitle') return this.helperTitle.asObservable();
        if (type === 'helperWindow') return this.helperWindow.asObservable();
        if (type === 'helperId') return this.helperId.asObservable();
    }

    updateCurrent(type, data) {
        if (type === 'local') this.local.next(data);
        if (type === 'buildingsList') this.buildingsList.next(data);
        if (type === 'building') this.building.next(data);
        if (type === 'helperText') this.helperText.next(data);
        if (type === 'helperTitle') this.helperTitle.next(data);
        if (type === 'helperWindow') this.helperWindow.next(data);
        if (type === 'helperId') this.helperId.next(data);
    }
}
