import { Component, OnInit, HostListener } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { EventEmitterService } from './services/event-emitter.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  bottomScrolled = false;

  constructor(private analytics: AnalyticsService) {
  }

  // Scroll Event Listener
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    // Bottom of page
    if ((window.innerHeight + window.scrollY +23) >= document.body.offsetHeight) {
      this.bottomScrolled = true;
      EventEmitterService.get('bottomScrolled').emit(this.bottomScrolled);
    } else if (this.bottomScrolled) {
      this.bottomScrolled = false;
      EventEmitterService.get('bottomScrolled').emit(this.bottomScrolled);
    }
  }

  ngOnInit(): void {
  }
}
