import { environment } from '../../../../environments/environment.prod';
import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '../../../auth';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { LayoutService } from '../../../@core/data/layout.service';
import { EventEmitterService } from '../../../services/event-emitter.service';
import { MokaService } from '../../../services/moka.service';
import { ToasterConfig, ToasterService, BodyOutputType, Toast } from 'angular2-toaster';
import { ObservablesService } from '../../../services/observables.service';


@Component({
  selector: 'external-header',
  styleUrls: ['./external-header.component.scss'],
  templateUrl: './external-header.component.html',
})
export class ExternalHeaderComponent {

  @Input() position = 'normal';
  loading: boolean = false
  corp: any = {}
  corps: any = []
  hasCorp : any;

  pageTitle : String;
  pageTitleColor: string
  pageImageColor: string

  hasDefaultStyles: boolean = true

  logoSrc: string
  logoTxt: string
  logoColor: string

  constructor(private sidebarService: NbSidebarService, public observablesService : ObservablesService) {

    EventEmitterService.get('corpChange').subscribe(data => this.changeUserLogo(data));  
    EventEmitterService.get('qrCodePage').subscribe(data => this.pageTitle = data);
    EventEmitterService.get('headerStyles').subscribe(data => this.changeHeaderStyle(data));
  }

  ngOnInit() {
    if (localStorage.getItem('corp')) {
      this.corps = JSON.parse(localStorage.getItem('usercorps'))
      this.corp = JSON.parse(localStorage.getItem('corp'));
      this.hasCorp = true
    }
  }

  changeHeaderStyle(styles) { 
    if (styles && styles != {}) {
      this.hasDefaultStyles = false
      if (styles.logo) {
        if (styles.logo.icon) {
          this.logoSrc = styles.logo.icon
        }
        if (styles.logo.text) {
          this.logoTxt = styles.logo.text
        }
        if (styles.logo.styles && styles.logo.styles.color) {
          this.logoColor = styles.logo.styles.color
        }
      }
      if (styles.page_title) {
        if (styles.page_title.title) {
          this.pageTitle = styles.page_title.title
        }
        if (styles.page_title.styles) {
          if (styles.page_title.styles.color) {
            this.pageTitleColor = styles.page_title.styles.color
          }
          if (styles.page_title.styles.image_color) {
            this.pageImageColor = styles.page_title.styles.image_color
          }
        }
      }
    }
  }

  changeUserLogo(_corp) {
    this.corps = _corp
    if (localStorage.getItem('corp')) {
      this.corp = JSON.parse(localStorage.getItem('corp'));
      this.hasCorp = true
    }
     EventEmitterService.get('updateCorp').emit()
  }
}
