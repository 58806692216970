import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  BalanceRelatedToGoalResponse,
  BigNumberResponse,
  ConsumptionComparisonResponse,
  EnvironmentsStatusResponse,
} from './models/OperationManager.model';

@Injectable({
  providedIn: 'root',
})
export class MokaOperationManagerService {
  public baseEndpoint = environment.baseURL;

  public url = '/api/mb/operation/manager';

  private httpOptions;

  constructor(private http: HttpClient) {
    let data;
    data = localStorage.getItem('auth_app_token');
    data = JSON.parse(data);
    data.value = 'Bearer ' + data.value;

    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Authorization: data.value,
    });
  }

  getBigNumbers(environmentList: string, schedule: boolean, interval?: string): Observable<BigNumberResponse> {
    let parameters: string = `/big-numbers/list?environmentList=${environmentList}&schedule=${schedule}&month=${interval || ''}`;
    return this.http.get<BigNumberResponse>(this.baseEndpoint + this.url + parameters, {
      headers: this.httpOptions,
    });
  }

  getEnvironmentsStatus(environmentList: string, interval?: string): Observable<EnvironmentsStatusResponse> {
    let parameters: string = `/environments/status?environmentList=${environmentList}&month=${interval || ''}`;
    return this.http.get<EnvironmentsStatusResponse>(this.baseEndpoint + this.url + parameters, {
      headers: this.httpOptions,
    });
  }

  replyInsight(id: string, response: any): Observable<any> {
    let parameters: string = `/reply/${id}`;
    return this.http.post(this.baseEndpoint + this.url + parameters, response, {
      headers: this.httpOptions,
    });
  }

  getBalanceRelatedToGoal(environmentList: string, interval?: string): Observable<BalanceRelatedToGoalResponse> {
    let parameters: string = `/environments/balance/goal?environmentList=${environmentList}&month=${interval || ''}`;
    return this.http.get<BalanceRelatedToGoalResponse>(this.baseEndpoint + this.url + parameters, {
      headers: this.httpOptions,
    });
  }

  getConsumptionComparison(environmentList: string, interval?: string): Observable<ConsumptionComparisonResponse> {
    let parameters: string = `/environments/consumption/comparison?environmentList=${environmentList}&month=${interval || ''}`;
    return this.http.get<ConsumptionComparisonResponse>(this.baseEndpoint + this.url + parameters, {
      headers: this.httpOptions,
    });
  }
}
