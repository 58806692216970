import { Component, OnInit, Input, ViewEncapsulation, HostListener, ElementRef, EventEmitter, Output } from '@angular/core';
import { ToasterService, Toast, BodyOutputType } from 'angular2-toaster';
import { Router } from '@angular/router';
import { MokaOperationManagerService } from '../../../services/new-dashboards/moka.operation-manager.service';


@Component({
  selector: 'ngx-insights',
  templateUrl: './insights.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./insights.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },

})
export class InsightsComponent implements OnInit {

  @Input() insights
  @Input() categoryList
  @Output() closeInsights = new EventEmitter<boolean>()
  @Output() refresh = new EventEmitter()

  loading: boolean = false
  clickCounter: number = 0

  filteredInsights: any;
  totalFilteredInsights: any = 0;
  totalInsightsByCategory: any = [];
  quantityCalculated: boolean = false;
  public checkboxValue: boolean;
  insightsByLocation: any;
  constructor(private _eref: ElementRef,
    private toasterService: ToasterService,
    private mokaOperationManagerService: MokaOperationManagerService,
    private router: Router) {
    this.checkboxValue = true;
    this.categoryList = [
      { name: "Conforto", category: "comfort", relatedCategories: ["comfort"], quantity: 0, selected: true },
      { name: "Consumo", category: "consumption", relatedCategories: ["consumption", "energy", "water"], quantity: 0, selected: true },
      { name: "Relatório", category: "report", relatedCategories: ["report", "monitor"], quantity: 0, selected: true },
      { name: "Crítico", category: "critical", relatedCategories: ["critical"], quantity: 0, selected: true },
      { name: "Equipamentos", category: "split", relatedCategories: ["split", "split_maintenance"], quantity: 0, selected: true }
    ];
  }

  ngOnInit() { }

  groupInsights() {

  }

  filterCategories() {

    this.totalFilteredInsights = 0;

    // Efetua uma cópia dos insights
    this.filteredInsights = JSON.parse(JSON.stringify(this.insights));

    // Filtra as categorias que estão selecionadas no checkbox
    let selectedCategories = this.categoryList.filter((category) => category.selected === true);

    let filteredList: any = [];

    for (var i = 0; i < this.filteredInsights.environmentList.length; i++) {

      let insight = this.filteredInsights.environmentList[i].insightList.filter((insight) => {
        return selectedCategories.some((category) => {
          // Adicionar em um array todas as categorias
          if (category.relatedCategories.includes(insight.category)) this.totalInsightsByCategory.push(insight.category);
          return category.category.includes(insight.category)
        });
      });

      let environment: any = Object.assign({}, this.filteredInsights.environmentList);
      delete environment[i].insightList;

      if (insight && insight.length > 0) {
        filteredList.push(environment[i]);
        filteredList[filteredList.length - 1].insightList = insight;
        filteredList[filteredList.length - 1].active = insight.length;
      }
    }

    this.filteredInsights.environmentList = filteredList;

    // Contador da quantidade total de insights filtrados
    for (var i = 0; i < this.filteredInsights.environmentList.length; i++) {
      this.totalFilteredInsights += this.filteredInsights.environmentList[i].active;
    }

    // Contador da quantidade total de cada insight
    if (!this.quantityCalculated) {

      for (let i = 0; i < this.categoryList.length; i++) {
        for (let j = 0; j < this.totalInsightsByCategory.length; j++) {
          if (this.categoryList[i].category === this.totalInsightsByCategory[j]) {
            this.categoryList[i].quantity++;
          }
        }
      }

      // Seta o checkbox como false para todas as categorias sem insights
      this.categoryList.map((category) => { if (category.quantity === 0) category.selected = false; });

      this.quantityCalculated = true;
    }
  }

  onClick(event) {

    //verificar se é o primeiro click. Se não for, aí fecha a lista de insights
    if (this.clickCounter > 0) {
      if (!this._eref.nativeElement.contains(event.target)) {
        this.closeInsights.emit()
      }
    }
    else {
      this.clickCounter++
    }
  }
  //Envia o feedback para o back-end e remove da lista de notificações
  // onFeedbackClick(insight, feedback){

  //   let serverResponse;

  //   this.serverApi.setInsightFeedback(insight, feedback).then(data => {
  //     serverResponse = data;

  //     if(serverResponse.status === 1) {
  //       this.showToast('error', 'Erro', 'Ocorreu algum erro ao enviar o feedback')

  //     } else {
  //       let index = this.insights.indexOf(insight);
  //       this.insights.splice(index, 1);



  //       // for (let i = 0; i < this.categoryList.length; i++) {
  //       //   if (this.categoryList[i].category === insight.category) {
  //       //     this.categoryList[i].quantity --;
  //       //     if (this.categoryList[i].quantity === 0) {
  //       //       this.categoryList[i].selected = false;
  //       //     }
  //       //     break;
  //       //   }
  //       // }



  //       if(feedback.url) this.router.navigateByUrl(feedback.url);
  //       //this.filterCategories();

  //     }
  //   });
  // }

  async onFeedbackClick(insight, feedback) {
    let changeToOff: boolean;
    let changeToOn: boolean;
    if (insight.event === 'airOnOutOfBusinessHour' || (insight.feedback[0].value === 'SIM' && insight.feedback[1].value === 'NAO')) {
      if (feedback.value === 'SIM') {
        changeToOff = true;
        changeToOn = false;
      } else if (feedback.value === 'NAO') {
        changeToOff = false;
        changeToOn = false;
      } else if (insight.feedback[0].value === 'OK') {
        changeToOff = false;
        changeToOn = false;
      }
    } else {
      changeToOff = false;
      changeToOn = false;
    }
    let responseObject = {
      response: feedback.value,
      changeToOn: changeToOn,
      changeToOff: changeToOff
    }
    await this.replyInsightService(insight, responseObject);
  }

  async replyInsightService(insight, response) {
    try {
      await this.mokaOperationManagerService.replyInsight(insight._id, response).subscribe((data) => {
        let serverResponse = data;
        if (serverResponse.status) {

          this.showToast('error', 'Erro', 'Não foi possível responder ao insight: ' + serverResponse.message);

        }
        else {
          this.showToast('success', 'Sucesso!', 'Insight respondido com sucesso!');
          let index = this.insights.indexOf(insight);
          this.insights.splice(index, 1);
          if (response.url)
            this.router.navigateByUrl(response.url);

        }
      });
    } catch (err) {
      this.showToast('error', 'Erro', 'Não foi possível responser ao insight.');
    }
  }


  private showToast(type, title, body) {

    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };

    this.toasterService.popAsync(toast);
  }
}
