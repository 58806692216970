import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CorpColorService {
  public baseEndpoint = environment.baseURL;

  //   public url = "/api/mb/equipment/";

  private httpOptions;

  constructor(private http: HttpClient) {
    let data;
    data = localStorage.getItem('auth_app_token');
    data = JSON.parse(data);
    data.value = 'Bearer ' + data.value;

    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Authorization: data.value,
    });
  }

  getCorpColor(): Observable<any> {
    const urlParam: string = `/api/mb/napp/user/get/`;

    return this.http.get<any>(this.baseEndpoint + urlParam, {
      headers: this.httpOptions,
    });
  }
}
