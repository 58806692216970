import { FormsModule } from '@angular/forms';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { CoreModule } from './@core/core.module';
import { AuthGuard } from './auth-guard.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from 'ngx-loading';
import { NbSpinnerModule } from '@nebular/theme';
import { NbCalendarModule } from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AnalyticsService } from './@core/utils/analytics.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { GestureConfig } from '@angular/material';
import { NgxEchartsModule } from 'ngx-echarts';
import { QRCodeModule } from 'angular2-qrcode';
import * as echarts from 'echarts';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    AppRoutingModule,
    NgxJsonViewerModule,
    NbSpinnerModule,
    NbCalendarModule,
    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    LoadingModule.forRoot({
      backdropBackgroundColour: '#FFFFFF',
      backdropBorderRadius: '2px',
      primaryColour: '#111218',
      secondaryColour: '#111218',
      tertiaryColour: '#111218',
    }),
    NgxEchartsModule.forRoot({
      echarts,
    }),
    QRCodeModule,
  ],
  bootstrap: [AppComponent],
  providers: [AuthGuard, { provide: APP_BASE_HREF, useValue: '/' }, { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }],
})
export class AppModule {
  constructor(private analytics: AnalyticsService) {
    this.analytics.trackPageViews();
  }
}
